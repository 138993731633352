import React from 'react';
import GreenChevron from '../../images/actions/chevron-green.png';
import GreyChevron from '../../images/actions/chevron-grey.png';

let Chevrons = chevronValue => {
    let htmlOutput = [];
    htmlOutput.push(<img className="chevron" src={GreenChevron} alt="Chevron" />);
    for (let i = 0; i < chevronValue; i++) {
        htmlOutput.push(<img className="chevron" src={GreenChevron} alt="Chevron" />);
    }
    for (let i = chevronValue; i < 4; i++) {
        htmlOutput.push(<img className="chevron" src={GreyChevron} alt="Chevron" />);
    }
    return htmlOutput;
};

export default Chevrons;
