import React from 'react';

import '../../styles/actions.scss';
import ActionImage from './ActionImage.js';

const ActionsGrid = ({ actions, truncate = false }) => {
    if (truncate) {
        actions = actions.slice(0, 3);
    }

    return (
        <div className="actionGrid">
            {actions.map(actionDict => (
                <ActionImage key={actionDict.ID} actionDict={actionDict} />
            ))}
        </div>
    );
};

export default ActionsGrid;
