import React from 'react';
import Chevrons from './ChevronRender';
//test

const ActionAttribute = ({ title, value, chevronValue }) => (
    <div className="actionAttribute">
        <span className="actionAttributeTitle">{title}</span>
        <span className="actionAttributeValue">
            {value}&nbsp;{typeof chevronValue !== 'undefined' && Chevrons(chevronValue)}
        </span>
    </div>
);

export default ActionAttribute;
