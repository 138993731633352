import React from 'react';

import infoOutline from '../../images/emissions/info-outline.svg';

import '../../styles/moreInfoModal.scss';

interface MoreInfoQAEntity {
    question: string;
    answer: string;
}

interface MoreInfoModalParams {
    title: string;
    body: MoreInfoQAEntity[];
    toggle: React.MouseEventHandler<HTMLButtonElement>;
}

const convertTextToLinks = (text: string) => {
    const words = text.split(/\s+/);
    return words.map((word: string, index: number) => {
        if (word.match(/^https?:\/\//)) {
            return (
                <React.Fragment key={index}>
                    <a href={word} target="_blank" rel="noreferrer">
                        {word}
                    </a>{' '}
                </React.Fragment>
            );
        } else if (word.match(/^[\w\.-]+@[\w\.-]+\.\w+/)) {
            return (
                <React.Fragment key={index}>
                    <a href={`mailto:${word}`}>{word}</a>{' '}
                </React.Fragment>
            );
        } else {
            return <React.Fragment key={index}>{word} </React.Fragment>;
        }
    });
};

const MoreInfoModal = ({ title, body, toggle }: MoreInfoModalParams) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <div className="button-close">
                    <button id="close" onClick={toggle}>
                        &times;
                    </button>
                </div>
                <div>
                    <h2>
                        <img
                            src={infoOutline}
                            alt="More Info"
                            style={{ marginBottom: 0, paddingRight: '5px' }}
                        />
                        {title}
                    </h2>
                </div>
                <div>
                    {body.map((questionAnswer, _) => {
                        return (
                            <div className="question-answer" key={questionAnswer.question}>
                                <p className="question">{questionAnswer.question}</p>
                                <p className="answer">
                                    {convertTextToLinks(questionAnswer.answer)}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default MoreInfoModal;
