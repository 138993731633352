import React, { useState } from 'react';
import { FirebaseContext } from '../../utils/firebase-provider.js';
import ActionImage from '../Actions/ActionImage.js';
import ActionsGrid from '../Actions/ActionsGrid.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

import '../../styles/actionStatus.scss';

import {
    faCirclePlus,
    faCircleXmark,
    faCircleCheck,
    faArrowRight,
} from '@fortawesome/free-solid-svg-icons';

const actionsText = {
    inProgress: {
        title: 'My goals in progress',
        subTitle: 'No goals added',
        subText: 'Building your list of goals by browsing all actions',
        icon: faCirclePlus,
    },
    completed: {
        title: 'My achieved actions',
        subTitle: 'No achieved actions to show',
        subText: 'Stay commited to achieving your goals!',
        icon: faCircleCheck,
    },
    notForMe: {
        title: 'Not for me',
        subTitle: 'No excluded actions to show',
        subText: 'Actions not for me',
        icon: faCircleXmark,
    },
};

const ActionStatus = ({ userActions, type }) => {
    const [truncateActions, setTruncateActions] = useState(false);

    return (
        <div className="userActions__section">
            <div className="userActions__section-header">
                <h3> {actionsText[type].title} </h3>
                {userActions.length > 0 && (
                    <button
                        className="viewMore"
                        onClick={() => setTruncateActions(!truncateActions)}
                    >
                        {truncateActions ? 'View More' : 'View Less'}
                        &emsp;
                        <FontAwesomeIcon icon={faArrowRight} />
                    </button>
                )}
            </div>
            {userActions.length == 0 ? (
                <>
                    <div className="userActions__empty">
                        <div className="userActions__empty-card">
                            <FontAwesomeIcon icon={actionsText[type].icon} className="emptyIcon" />
                            {type == 'inProgress' && (
                                <Link to="/act" className="actionsLink" id="browseActionsLink">
                                    Browse All Actions
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="userActions__empty-subtext">
                        <h4>{actionsText[type].subTitle}</h4>
                        <h5>{actionsText[type].subText}</h5>
                    </div>
                </>
            ) : (
                <ActionsGrid actions={userActions} truncate={truncateActions} />
            )}
        </div>
    );
};

export default ActionStatus;
