import React from 'react';
import { FirebaseContext } from '../../utils/firebase-provider.js';
import ActionStatus from './ActionStatus.js';

import '../../styles/global.scss';

const UserActions = ({ actionsDict }) => {
    const context = React.useContext(FirebaseContext);
    const inProgress = [];
    const completed = [];
    const notForMe = [];

    if (context.status == 'logged') {
        const { actionsCompleted, actionsInProgress, actionsNotForMe } = context.userProfile;
        actionsInProgress?.forEach(actionNumber => {
            inProgress.push(actionsDict[actionNumber]);
        });
        actionsCompleted?.forEach(actionNumber => {
            completed.push(actionsDict[actionNumber]);
        });
        actionsNotForMe?.forEach(actionNumber => {
            notForMe.push(actionsDict[actionNumber]);
        });
    }

    return (
        <div>
            <h2 className="userActions__header">My Actions</h2>
            <ActionStatus type={'inProgress'} userActions={inProgress} />
            <ActionStatus type={'completed'} userActions={completed} />
            <ActionStatus type={'notForMe'} userActions={notForMe} />
        </div>
    );
};

export default UserActions;
