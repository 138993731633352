import React, { useState, useEffect } from 'react';
import '../../styles/userEmissions.scss';
import EmissionResult from '../Emissions/EmissionResult';
import { navigate } from 'gatsby';
import { Button } from '../Button';
import MoreInfoModal from '../Modals/MoreInfoModal';

import { FirebaseContext } from '../../utils/firebase-provider.js';
import { moreInfoUserEmissionsQuestionsAnswers } from '../../utils/emission-utils/EmissionQuestions';
import {
    emissionCalculator,
    emissionGlobal,
    emissionPerCountry,
} from '../../utils/emission-utils/index.js';
import { formatNumber } from '../../utils/emission-utils/FormatNumber.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const UserEmissions = () => {
    const [summedEmissionResult, setSummedEmissionResult] = useState(0);
    const [summedCountryEmissionResult, setSummedCountryEmissionResult] = useState(0);
    const [summedGlobalEmissionResult, setSummedGlobalEmissionResult] = useState(0);
    const [userCountry, setUserCountry] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);

    const context = React.useContext(FirebaseContext);
    const userProfile = context.userProfile;
    const { result: userEmissionResult, error: errorCalculator } = React.useMemo(
        () => emissionCalculator(userProfile),
        [userProfile]
    );
    const sumEmissionResult = emissionResult =>
        formatNumber(
            Object.values(emissionResult).reduce((accum, emission) => {
                return accum + (emission ? emission : 0);
            }, 0)
        );

    const calcEmissionDifference = (userEmissions, comparedToEmissions) => {
        let percentGreater = Math.round(
            ((parseInt(userEmissions) - parseInt(comparedToEmissions)) / parseInt(userEmissions)) *
                100
        );
        return percentGreater < 0
            ? percentGreater.toString() + '% Less'
            : percentGreater.toString() + '% More';
    };

    useEffect(() => {
        setSummedEmissionResult(sumEmissionResult(userEmissionResult));
        setSummedCountryEmissionResult(sumEmissionResult(emissionPerCountry(userCountry)));
        setUserCountry(
            userProfile && userProfile.hasOwnProperty('userCountryCode')
                ? userProfile['userCountryCode']
                : 'US'
        );
        setSummedGlobalEmissionResult(sumEmissionResult(emissionGlobal()));
    }, [context]);

    const toggleModal = function() {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div className="userEmissions">
            <h2 className="userEmissions__header">My Emission</h2>
            <div className="userEmissions__content-container">
                <div className="userEmissions__info">
                    <div className="userEmissions__info-header">
                        <div className="userEmissions__info-text">My carbon footprint is</div>
                        <div className="userEmissions__info-text--highlight">
                            {summedEmissionResult} tonnes per year
                            <sub>
                                <button onClick={toggleModal}>
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </button>
                                {isModalOpen && (
                                    <MoreInfoModal
                                        title="Emissions Calculations"
                                        body={moreInfoUserEmissionsQuestionsAnswers}
                                        toggle={toggleModal}
                                    />
                                )}
                            </sub>
                        </div>
                    </div>
                    <div className="userEmissions__contribution">
                        <div className="userEmissions__contribution-card userEmissions__contribution-card--average ">
                            <h3>
                                {calcEmissionDifference(
                                    summedEmissionResult,
                                    summedCountryEmissionResult
                                )}
                            </h3>
                            <div className="userEmissions__contribution-card-subtext">
                                than {userCountry} average
                            </div>
                        </div>
                        <div className="userEmissions__contribution-card userEmissions__contribution-card--global">
                            <h3>
                                {calcEmissionDifference(
                                    summedEmissionResult,
                                    summedGlobalEmissionResult
                                )}
                            </h3>
                            <div className="userEmissions__contribution-card-subtext">
                                than Global average
                            </div>
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            navigate('/emissions');
                        }}
                        className="profile-btn"
                        buttonStyle="btn--primary--solid"
                    >
                        Refine My Emissions Profile
                    </Button>
                </div>
                <EmissionResult
                    className="userEmissions__info"
                    summedEmissionResult={summedEmissionResult}
                    summedCountryEmissionResult={summedCountryEmissionResult}
                    summedGlobalEmissionResult={summedGlobalEmissionResult}
                    userCountry={userCountry}
                />
            </div>
        </div>
    );
};

export default UserEmissions;
