import React from 'react';
import { Link } from 'gatsby';
import { encodeActionURL } from '../../utils/link-utils.js';
import ActionAttributes from './ActionAttributes.js';

import '../../styles/actions.scss';

const Action = ({ actionDict }) => {
    return (
        <div className="action">
            <Link to={encodeActionURL(actionDict['ID'], actionDict['Action Title'])}>
                <img className="actionImg" src={actionDict['ImageUrl']} />
                <h4 className="actionTitle">{actionDict['Action Title']}</h4>
                <ActionAttributes actionDict={actionDict} />
            </Link>
        </div>
    );
};

export default Action;
