import React from 'react';
import ActionAttribute from './ActionAttribute.js';

const calculateChevron = earthPoints => {
    let numberOfChevrons = 0;

    if (earthPoints >= 0 && earthPoints <= 1) {
        numberOfChevrons = 0;
    } else if (earthPoints > 1 && earthPoints < 20) {
        numberOfChevrons = 1;
    } else if (earthPoints >= 20 && earthPoints < 40) {
        numberOfChevrons = 2;
    } else if (earthPoints >= 40 && earthPoints <= 60) {
        numberOfChevrons = 3;
    } else if (earthPoints > 60) {
        numberOfChevrons = 4;
    } else {
        numberOfChevrons = 0;
    }
    return numberOfChevrons;
};

const ActionAttributes = ({ actionDict }) => (
    <div className="actionAttributes">
        {/*<ActionImpact
            actionDict={actionDict}
        />*/}
        <ActionAttribute
            title="Earth Points"
            value={actionDict['Earth Points']}
            chevronValue={calculateChevron(actionDict['Earth Points'])}
        />
        <ActionAttribute title="Ease" value={actionDict['Ease rating']} />
    </div>
);

export default ActionAttributes;
