import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import UserActions from '../components/Profile/UserActions';
import SEO from '../components/seo';
import '../styles/global.scss';
import '../styles/emissions.scss';
import UserEmissions from '../components/Profile/UserEmissions';
import { FirebaseContext } from '../utils/firebase-provider.js';

const Profile = ({ pageContext: { profile, actionsDict } }) => {
    const context = React.useContext(FirebaseContext);

    return (
        <Layout navBarType={profile.navBarType}>
            <SEO title={profile.display === 'myActions' ? 'My Actions' : 'My Emission'} />
            <section>
                <div>
                    {context.error && (
                        <div className="alert alert-warning" role="alert">
                            {context.error}
                        </div>
                    )}
                    <div className="profile-flex-container">
                        {
                            {
                                myActions: <UserActions actionsDict={actionsDict} />,
                                myEmissions: (
                                    <div className="profile-flex-components">
                                        <UserEmissions />
                                    </div>
                                ),
                            }[profile.display]
                        }
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default Profile;
