const specialCharacters = {
    and: '&',
    quest: '?',
    numeric: '#',
    percentage: '%',
    comma: ',',
    plus: '+',
};

const baseActionUrl = '/act/';
const baseCategoryUrl = '/act/category/';
const baseArticleUrl = '/articles/';
const baseActivismGroupUrl = '/activism-group/';
const baseProfileUrl = '/profile/';
const baseAppUrl = '/app/';
const separator = '-';

function encodeURL(baseurl, title) {
    if (!title) {
        return '';
    }
    let formattedTitle = title.trim();
    Object.entries(specialCharacters).forEach(([newStr, oldStr]) => {
        while (formattedTitle.includes(oldStr)) {
            formattedTitle = formattedTitle.replace(oldStr, newStr);
        }
    });
    formattedTitle = formattedTitle.replace(/\s+/g, separator);
    formattedTitle = formattedTitle.toLowerCase();
    formattedTitle = baseurl + encodeURIComponent(formattedTitle);
    return formattedTitle;
}

function encodeActionURL(id, title) {
    return encodeURL(baseActionUrl, id + separator + title);
}

function encodeActivismGroupURL(groupName) {
    return encodeURL(baseActivismGroupUrl, groupName);
}

function encodeActionRedirectUrl(id) {
    return encodeURL(baseActionUrl, id + separator) + '*';
}

function encodeCategoryURL(category) {
    return encodeURL(baseCategoryUrl, category);
}

function encodeArticleURL(articleSlug) {
    return encodeURL(baseArticleUrl, articleSlug);
}

function encodeProfileURL(profileDisplay) {
    return encodeURL(baseProfileUrl, profileDisplay);
}

function encodeCloudinaryId(publicId) {
    return encodeURL('', publicId);
}

module.exports = {
    encodeActionURL,
    encodeActivismGroupURL,
    encodeActionRedirectUrl,
    encodeCategoryURL,
    encodeArticleURL,
    encodeProfileURL,
    encodeCloudinaryId,
};
